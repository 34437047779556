<form *ngIf="form" [formGroup]="form" class="{{fieldData.form.display?.cssClasses}}" [ngClass]="{'uk-hidden clear-style': (hideField || !fieldData.form.display.visible)}">
<!--  {{fieldData.name | json}}-->
  <div *ngIf="fieldData.label?.text" class="uk-form-label uk-margin-bottom {{fieldData.label?.cssClasses}}"
       [attr.for]="fieldData.name" style="{{fieldData.label.style}}">
    {{fieldData.label?.text + (fieldData.form.mandatory ? ' (*)' : '')}}
  </div>
  <ng-container *ngIf="fieldData.form.description?.showLess; else noReadMore">
    <div class="uk-form-description uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}}"
         *ngIf="fieldData.form.description.text" style="{{fieldData.form.description.style}}">
      <read-more-text [maxHeight]="46" [text]="fieldData.form.description.text"></read-more-text>
    </div>
  </ng-container>
  <ng-template #noReadMore>
    <div *ngIf="fieldData.form.description?.text" style="{{fieldData.form.description.style}}"
         class="uk-form-description uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}}"
         [innerHTML]="fieldData.form.description.text | safeUrl: 'html'">
    </div>
  </ng-template>

  <div *ngIf="fieldData.kind !== 'external'">

  <div *ngIf="fieldData.typeInfo.multiplicity; else noMultiplicity">
    <div appSortable (positionChanged)="onPositionChanged($event)" id="sortable-container">
      <div *ngFor="let control of fieldAsFormArray().controls; let i=index" id="{{i}}" [attr.data-index]="i"
           class="uk-width-expand uk-grid">
        <div [formGroupName]="i" class="uk-width-9-10 uk-margin-medium-left"
             [ngClass]="{'uk-margin-bottom formGroupElement': fieldData.form.display?.hasBorder}">
          <div *ngIf="fieldAsFormArray().length > 1 && !readonly" class="uk-flex uk-flex-right">
            <span class="uk-sortable-handle uk-text-center" uk-icon="icon: move"></span>
          </div>
          <div *ngFor="let control of fieldData?.subFields; let j=index">
            <div *ngIf="!control.deprecated" class="">
              <div *ngIf="control.typeInfo.type === 'composite'">
                <app-composite-field [fieldData]="control" [subVocabularies]="subVocabularies" [readonly]="readonly"
                                     [vocabularies]="vocabularies" [editMode]="editMode" [position]="i"
                                     (hasChanges)="unsavedChangesPrompt()" (handleBitSets)="handleBitsetOfChildren($event)"
                                     (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
                </app-composite-field>
              </div>
              <div *ngIf="control.typeInfo.type === 'chooseOne'">
                <app-choose-one [fieldData]="control" [subVocabularies]="subVocabularies" [editMode]="editMode"
                                [vocabularies]="vocabularies" (hasChanges)="unsavedChangesPrompt()"
                                [position]="i" (handleBitSets)="handleBitsetOfChildren($event)"
                                (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
                </app-choose-one>
              </div>
              <div *ngIf="control.typeInfo.type === 'vocabulary' || control.typeInfo.type === 'select'" class="uk-form-controls uk-margin-medium-top">
                <app-vocabulary-field [fieldData]="control" [subVocabularies]="subVocabularies" [editMode]="editMode"
                                      [vocabularies]="vocabularies" (hasChanges)="unsavedChangesPrompt()"
                                      [position]="i" (handleBitSets)="handleBitsetOfChildren($event)"
                                      (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
                </app-vocabulary-field>
              </div>
              <div *ngIf="control.typeInfo.type === 'string' || control.typeInfo.type === 'email'||
                        control.typeInfo.type === 'phone' || control.typeInfo.type === 'url' ||
                        control.typeInfo.type === 'double'">
                <app-string-url-email-field [fieldData]="control" [position]="i" (hasChanges)="unsavedChangesPrompt()"
                                            [editMode]="editMode" [readonly]="readonly"
                                            (handleBitSets)="handleBitsetOfChildren($event)"
                                            (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
                </app-string-url-email-field>
              </div>
              <div *ngIf="control.typeInfo.type === 'number'">
                <app-number-field [fieldData]="control" [position]="i" (hasChanges)="unsavedChangesPrompt()"
                                  [editMode]="editMode" [readonly]="readonly"
                                  (handleBitSets)="handleBitsetOfChildren($event)"
                                  (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
                </app-number-field>
              </div>
              <div *ngIf="control.typeInfo.type === 'checkbox'" class="uk-form-controls">
                <app-checkbox-field [fieldData]="control" [position]="i" (hasChanges)="unsavedChangesPrompt()"
                                    (handleBitSets)="handleBitsetOfChildren($event)" [editMode]="editMode"
                                    (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
                </app-checkbox-field>
              </div>
              <div *ngIf="control.typeInfo.type === 'largeText'">
                <app-large-text-field [fieldData]="control" [position]="i" (hasChanges)="unsavedChangesPrompt()"
                                      [readonly]="readonly"
                                      (handleBitSets)="handleBitsetOfChildren($event)" [editMode]="editMode"
                                      (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
                </app-large-text-field>
              </div>
              <div *ngIf="control.typeInfo.type === 'radio'" class="uk-form-controls">
                <app-radio-button-field [fieldData]="control" [position]="i" [editMode]="editMode" [readonly]="readonly"
                                        (hasChanges)="unsavedChangesPrompt()" (handleBitSets)="handleBitsetOfChildren($event)"
                                        (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
                </app-radio-button-field>
              </div>
              <div *ngIf="control.typeInfo.type === 'richText'" class="uk-form-controls">
                <app-rich-text-field [fieldData]="control" [position]="i" [editMode]="editMode" [readonly]="readonly"
                                     (hasChanges)="unsavedChangesPrompt()"
                                     (handleBitSets)="handleBitsetOfChildren($event)"
                                     (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
                </app-rich-text-field>
              </div>
              <div *ngIf="control.typeInfo.type === 'date'">
                <app-date-field [fieldData]="control" [position]="i" (hasChanges)="unsavedChangesPrompt()"
                                (handleBitSets)="handleBitsetOfChildren($event)" [editMode]="editMode"
                                (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
                </app-date-field>
              </div>
              <div *ngIf="control.typeInfo.type === 'scale'">
                <app-scale-field [fieldData]="control" [position]="i" (hasChanges)="unsavedChangesPrompt()"
                                 (handleBitSets)="handleBitsetOfChildren($event)" [editMode]="editMode"
                                 (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
                </app-scale-field>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="fieldAsFormArray().length > 1 && !readonly"
             class="uk-width-expand uk-padding-remove uk-margin-small-top uk-grid uk-child-width-1-2">
          <div class="">
            <div>
              <a (click)="move(i-1, i)"><span class="material-icons">north</span></a>
            </div>
            <div>
              <a (click)="move(i+1, i)"><span class="material-icons">south</span></a>
            </div>
          </div>
          <div class="uk-padding-small">
            <a class="remove-element"
               (click)="remove(i); updateBitSetOfComposite(fieldData, i); unsavedChangesPrompt();">
              <span class="material-icons">clear</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!readonly" class="uk-width-9-10 uk-text-center uk-margin-top uk-margin-bottom">
      <a class="add-new-element uk-flex uk-flex-middle uk-flex-center" (click)="pushComposite(fieldData)">
        <span class="material-icons">add</span>Add &nbsp;<span [innerHTML]="fieldData.label.text"></span>
      </a>
    </div>
  </div>

  <ng-template #noMultiplicity>
    <div class="uk-width-1-1 uk-display-inline-block" [ngClass]="{'uk-margin-bottom formGroupElement': fieldData.form.display?.hasBorder}">
      <div *ngFor="let control of fieldData?.subFields; let j=index">
        <div *ngIf="!control.deprecated" class="">
          <div *ngIf="control.typeInfo.type === 'composite'">
            <app-composite-field [fieldData]="control" [subVocabularies]="subVocabularies" [vocabularies]="vocabularies"
                                 [editMode]="editMode" [readonly]="readonly"
                                 (handleBitSets)="handleBitsetOfChildren($event)" (hasChanges)="unsavedChangesPrompt()"
                                 (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
            </app-composite-field>
          </div>

          <div *ngIf="control.typeInfo.type === 'chooseOne'">
            <app-choose-one [fieldData]="control" [subVocabularies]="subVocabularies" [vocabularies]="vocabularies"
                            [editMode]="editMode" (handleBitSets)="handleBitsetOfChildren($event)"
                            (hasChanges)="unsavedChangesPrompt()"
                            (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
            </app-choose-one>
          </div>

          <div *ngIf="control.typeInfo.type === 'vocabulary' || control.typeInfo.type === 'select'" class="uk-form-controls uk-margin-medium-top">
            <app-vocabulary-field [fieldData]="control" [subVocabularies]="subVocabularies" [editMode]="editMode"
                                  [vocabularies]="vocabularies" (hasChanges)="unsavedChangesPrompt()"
                                  (handleBitSets)="handleBitsetOfChildren($event)"
                                  (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
            </app-vocabulary-field>
          </div>

          <div *ngIf="control.typeInfo.type === 'string' || control.typeInfo.type === 'url' ||
                    control.typeInfo.type === 'email' || control.typeInfo.type === 'phone' ||
                    control.typeInfo.type === 'double'">
            <app-string-url-email-field [fieldData]="control" [editMode]="editMode" [readonly]="readonly"
                                        (hasChanges)="unsavedChangesPrompt()"
                                        (handleBitSets)="handleBitsetOfChildren($event)"
                                        (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
            </app-string-url-email-field>
          </div>

          <div *ngIf="control.typeInfo.type === 'number'">
            <app-number-field [fieldData]="control" [editMode]="editMode" [readonly]="readonly"
                              (hasChanges)="unsavedChangesPrompt()"
                              (handleBitSets)="handleBitsetOfChildren($event)"
                              (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
            </app-number-field>
          </div>

          <div *ngIf="control.typeInfo.type === 'checkbox'" class="uk-form-controls">
            <app-checkbox-field [fieldData]="control" [editMode]="editMode" (hasChanges)="unsavedChangesPrompt()"
                                (handleBitSets)="handleBitsetOfChildren($event)"
                                (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
            </app-checkbox-field>
          </div>

          <div *ngIf="control.typeInfo.type === 'largeText'" class="uk-form-controls">
            <app-large-text-field [fieldData]="control" [readonly]="readonly" (hasChanges)="unsavedChangesPrompt()"
                                  (handleBitSets)="handleBitsetOfChildren($event)" [editMode]="editMode"
                                  (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
            </app-large-text-field>
          </div>

          <div *ngIf="control.typeInfo.type === 'richText'" class="uk-form-controls">
            <app-rich-text-field [fieldData]="control" [editMode]="editMode" [readonly]="readonly"
                                 (hasChanges)="unsavedChangesPrompt()"
                                 (handleBitSets)="handleBitsetOfChildren($event)"
                                 (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
            </app-rich-text-field>
          </div>

          <div *ngIf="control.typeInfo.type === 'radio'" class="uk-form-controls">
            <app-radio-button-field [fieldData]="control" [readonly]="readonly" (hasChanges)="unsavedChangesPrompt()"
                                    (handleBitSets)="handleBitsetOfChildren($event)" [editMode]="editMode"
                                    (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
            </app-radio-button-field>
          </div>
          <div *ngIf="control.typeInfo.type === 'date'">
            <app-date-field [fieldData]="control" [editMode]="editMode" (hasChanges)="unsavedChangesPrompt()"
                            (handleBitSets)="handleBitsetOfChildren($event)"
                            (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
            </app-date-field>
          </div>
          <div *ngIf="control.typeInfo.type === 'radioGrid'">
            <app-radio-grid [fieldData]="control">
            </app-radio-grid>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  </div>

  <div *ngIf="fieldData.form.suggestion?.text" class="uk-margin-top">
    <div class="uk-suggestion uk-width-9-10 {{fieldData.form.suggestion.cssClasses}}" style="{{fieldData.form.suggestion.style}}"
         [innerHTML]="fieldData.form.suggestion.text">
    </div>
  </div>

</form>
<!--<pre>{{ form.value | json }}</pre>-->
